<template>
    <div class="container_error" v-if="$store.state.cliquable==true">
        <div class="title">
            <span class="scotch1"></span>
            <span>{{ page_compte().code_error}}</span>
            <div class="title_desc">{{ page_compte().title}}</div>
            <span class="scotch2"></span>
        </div>
        <div class="desc">
            <span class="scotch3"></span>
            <span>{{ page_compte().descP1}}</span>
            <span class="desc_link pointer" @click="$router.push('/produits/rgpd')">{{ page_compte().desc_link_text}}</span>
            <span class="scotch4"></span>
        </div>
    </div>
</template>

<script>
import page_not from '@/json/page_notFound.json'
export default{
    name: "NotFound",
    data(){
        return{
            page_not:page_not
        }
    },
    methods:{
        page_compte(){
            return this.page_not[this.$store.state.codeLangue]
        },
    },
    mounted(){
        this.$store.state.cliquable=true
    }
}
</script>
<style scoped>
.container_error{
    display:flex;
    align-items:center;
    height:100%;
    background-color:#022873;
    flex-direction: column;
    background-image: url("../assets/error_404.png");
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
}

.scotch4{
    width: 105px;
    height: 50px;
    right: -49px;
    bottom: -26px;
    position: absolute;
    background: rgba(255, 255, 200, 0.9);
    transform: rotate(-60deg);
}
.scotch3{
    width: 105px;
    height: 50px;
    left: -36px;
    top: -25px;
    position: absolute;
    background: rgba(255, 255, 200, 0.9);
    transform: rotate(-43deg);
}
.scotch2{
    width: 124px;
    height: 50px;
    bottom: -18px;
    right: -60px;
    position: absolute;
    background: rgba(255, 255, 200, 0.9);
    transform: rotate(-45deg);
}

.scotch1{
    width: 100px;
    height: 50px;
    left: -45px;
    top: -22px;
    position: absolute;
    background: rgba(255, 255, 200, 0.9);
    transform: rotate(-40deg);
}

.desc_link{
    text-decoration: underline
}

.desc, .title{
    /*position: absolute;*/
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    /*width: -webkit-fit-content;
    width: -moz-fit-content;*/
    width: fit-content;
    padding-right: 40px;
    padding-left: 40px;
    word-wrap: break-word;
    hyphens:auto;
}

.desc{
    margin-top: 92px;
    font-size: 26px;
    background-color: #fff;
    transform: skewY(-4deg);
    border-bottom-left-radius: 70px;
    border-top-right-radius: 50px;
    border: 3px dashed #022873;
    color: #022873;
    margin-left: 40px;
    margin-right: 42px;
}
.title{
    margin-top: 180px;
    font-size: 50px;
    background-color: #022873;
    transform: skewY(-4deg);
    border-bottom-left-radius: 70px;
    border-top-right-radius: 50px;
    border: 3px dashed #fff;
    color: #fff;
    margin-left: 48px;
    margin-right: 58px;
}

.title_desc{
    font-size: 20px;
    line-height: 30px;
}

.bande_general{
    width: 100%;
    height: 50px;
    position: absolute;
    background-color: red;
    background-image: repeating-linear-gradient(45deg, #fff, #fff 50px, rgba(255,255,255,0) 50px, rgba(255,255,255,0) 100px);
    top: 445px;
}

.bande1{
    transform: skewY(-25deg);
}

.bande2{
    transform: skewY(10deg);
}

@media screen and (max-width: 700px) {
    .container_error {
        min-height:630px;
    }
}

@media screen and (max-width: 400px) {
    .container_error {
        min-height:825px;
    }
}


</style>