<template>
    <Modale
      :revele="revelePopup"
      :toggleModale="toggleModale"
      :title="titlePopup"
      :content="contentPopup"
      :type="typePopup"
    />

    <div class="main">
        <div class="main_general">
            <div :class="page_global().bloc1.favicon" class="favicon_message will-fadeIn"></div>
            <div class="right">
                <h1 class="title will-fadeIn">{{ page_contact().bloc1.titre }}</h1>
                <h2 class="sub_title will-fadeIn">{{ page_contact().bloc1.desc }}</h2>
                <div class="form">
                    <input class="will-fadeIn" id="mail_form" type="email" v-model="email" :placeholder="page_contact().bloc1.placeholder1">
                    <select id="objet_form" class="pointer will-fadeIn" v-model="objet" required>
                        <option value="" selected hidden>{{ page_contact().bloc1.placeholder2 }}</option>
                        <option v-for="(option, indexOption) in page_contact().bloc1.options" :key="indexOption">{{ option }}</option>
                    </select>
                    <textarea class="will-fadeIn" id="message_form" v-model="message" :placeholder="page_contact().bloc1.placeholder3"></textarea>
                </div>
                <div id="check_form" class="checkbox_container will-fadeIn">
                    <input class="pointer" type="checkbox" v-model="luEtApprouve">
                    <div class="checkbox_text">{{ page_contact().bloc1.checkbox }}</div>
                </div>
                <div class="boutton_offre will-fadeIn" @click="sendForm()">
                    <p class="texte_boutton_offre">{{ page_contact().bloc1.bouton }}</p>
                </div>
            </div>
        </div>
        <div class="infos">
            <div class="infos_contact">
                <div class="infos_table">
                    <div class="will-fadeIn fadeFromRight favicon" :class="page_global().bloc2.favicon_adr"></div>
                    <div class="infos_text will-fadeIn fadeFromRight">
                        <div>{{ page_contact().bloc2.adresseL1 }}</div>
                        <div>{{ page_contact().bloc2.adresseL2 }}</div>
                    </div>
                </div>
                <div class="infos_table">
                    <div class="will-fadeIn fadeFromRight favicon" :class="page_global().bloc2.favicon_hor"></div>
                    <div class="infos_text will-fadeIn fadeFromRight">
                        <div v-for="(horaire, indexHoraire) in page_contact().bloc2.horaires" :key="indexHoraire">{{ horaire }}</div>
                    </div>
                </div>
                <div class="paragraphe will-fadeIn fadeFromRight">{{ page_contact().bloc2.social }}</div>
                <div class="reseaux">
                    <div v-for="(reseau, indexReseau) in page_global().bloc2.reseaux" :key="indexReseau" :class="reseau" class="reseau_favicon will-fadeIn fadeFromRight"></div>
                </div>
            </div>
            <img class="pure_white_nina will-fadeIn fadeFromRight" src="../assets/pure_white_nina.png">
            <img class="contact_img1 will-fadeIn fadeFromRight" src="../assets/contact_img1.png">
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import json_config from "@/json/config.json";
import page_glb from "@/json/page_global.json";
import page_cont from "@/json/page_contact.json";
import Modale from '@/components/Modale.vue';
import page_pop from '@/json/page_popup.json'

export default {
    name: 'Contact',
    components: {
        Modale
    },
    data(){
        return{
            page_pop: page_pop,
            page_glb: page_glb,
            page_cont: page_cont,
            email:"",
            objet:"",
            message:"",
            luEtApprouve: false,
            inputs: ["mail_form", "objet_form", "message_form", "check_form"],
            revelePopup: false,
            titlePopup: "",
            contentPopup: "",
            typePopup: ""
        }
    },
    methods:{
        page_contact(){
            return this.page_cont[this.$store.state.codeLangue]
        },
        page_global(){
            return this.page_glb.global.contact
        },
        page_popup(){
            return this.page_pop[this.$store.state.codeLangue]
        },
        page_popup_global(){
            return this.page_pop.global
        },
        sendForm(){
            if(this.email!="" && this.objet!="" && this.message!="" && this.luEtApprouve==true){
                var mesDonnees = new FormData();
                mesDonnees.set("mail", this.email);
                mesDonnees.set("objet", this.objet);
                mesDonnees.set("message", this.message);

                axios({
                    method: 'post',
                    url: json_config.phpFiles_url + '/contact.php',
                    data: mesDonnees
                })
                .then((result) => {

                    if(result.data=="send"){
                        this.openModale(true, "contact_info_send", "contact_info_send", this.page_popup_global().info.code)
                        this.email=""
                        this.objet=""
                        this.message=""
                        this.luEtApprouve=false
                    }
                    else if(result.data=="not_send"){
                        this.openModale(true, "contact_echec_not_send", "contact_echec_not_send", this.page_popup_global().echec.code)
                    }
                    else if(result.data=="invalid_email"){
                        this.openModale(true, "contact_echec_invalid_email", "contact_echec_invalid_email", this.page_popup_global().echec.code)
                    }
                })
            }
            else{
                this.inputs.forEach(
                    element=> {
                        var champ_form = document.getElementById(element)
                        
                        var isTrue = false

                        if(element=="check_form"){
                            if(champ_form.getElementsByTagName("input")[0].checked){
                                isTrue = true
                            }
                        }
                        else if(champ_form.value!=""){
                            isTrue = true
                        }
                    
                        if(!isTrue){
                            champ_form.classList.add('vibrate');
            
                            // remove the class after the animation completes
                            setTimeout(function() {
                                champ_form.classList.remove('vibrate');
                            }, 300);
                        }
                    }
                )
            }
        },
        toggleModale(){
            this.revelePopup=""
        },
        openModale(revele, titlePopup, contentPopup, typePopup){
            this.revelePopup = revele
            this.titlePopup = titlePopup
            this.contentPopup = contentPopup
            this.typePopup = typePopup
        },
    },
    mounted(){
        this.$store.state.cliquable=true
    }
}
</script>

<style scoped>

    .boutton_offre{
        position: inherit;
        display: table;
    }

    .vibrate{
        position: relative;
        animation: shake .1s linear;
        animation-iteration-count: 3;
    }

    .main{
        position: relative;
        min-height:100%;
        background-color: #022873;
    }

    .main_general{
        text-align: left;
        display: flex;
        margin-left: 300px;
        padding-top: 154px;
        padding-bottom: 46px;
        color: #ffffff;
    }

    .title{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
    }

    .right{
        margin-left:21px;
    }

    .favicon_message{
        font-size: 40px;
    }

    .sub_title{
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
    }

    .form{
        display: flex;
        flex-direction: column;
    }

    input[type=email], textarea, select{
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
        padding-top: 21px;
        padding-left: 8px;
        padding-bottom: 6px;
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        -webkit-box-shadow: 0 0 0px 1000px #022873 inset;
    }

    textarea{
        height:129px
    }

    input:focus-visible, textarea:focus-visible, select:focus-visible{
        outline: none;
    }

    input::placeholder, textarea::placeholder, select:invalid{
        color: #ABB2BB
    }

    .form > *{
        margin-top: 16px;
    }

    .form:first-child{
        margin-top: 36px;
    }

    .checkbox_text{
        padding-left: 9px;
        display: table-cell;
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
    }

    .checkbox_container{
        margin-top: 22px;
        margin-bottom: 24px;
        display: table
    }

    input[type=checkbox]{
        border-color: #ffffff
    }

    .infos{
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: left;
    }

    .infos_table{
        margin-bottom: 60px;
        display: flex;
    }
    /*
    .infos_contact{
        position: absolute;
        margin-top: 210px;
        left: 139px;
    }*/

    .infos_contact{
        position: absolute;
        margin-top: 300px;
        left: 300px;
        z-index: 1
    }

    .infos_text{
        margin-left: 18px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .favicon{
        font-size: 27px;
    }

    .pure_white_nina{
        position:absolute;
        right:0;
        top: 152px;
        z-index:1;
    }

    .paragraphe{
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }

    .reseaux{
        margin-top: 20px;
    }

    .reseau_favicon{
        font-size: 40px;
        width: 60px;
    }

    .contact_img1{
        width: 700px;
    }

    @media screen and (max-width: 1600px){
        .infos_contact{
            margin-top: 250px;
            left: 250px;
        }

        .main_general{
            margin-left: 200px;
        }

        .contact_img1{
            width: 600px;
        }
    }

    @media screen and (max-width: 1400px){
        .infos_contact{
            margin-top: 210px;
            left: 139px;
        }

        .main_general{
            margin-left: 126px;
        }

        .contact_img1{
            width: inherit;
        }
    }

    @media screen and (max-width: 1200px){
        .contact_img1{
            width: 400px;
        }

        .infos_contact{
            margin-top: 156px;
        }

        .pure_white_nina{
            top: 120px;
        }
    }

    @media screen and (max-width: 1150px){
        .contact_img1{
            width: 350px;
        }

        .pure_white_nina{
            top: 80px;
        }

        .infos_contact{
            margin-top: 125px;
            left: 110px;
        }

        .infos_table{
            margin-bottom: 30px;
        }

        .main_general{
            margin-left: 80px;
        }
    }

    @media screen and (max-width: 1050px){
        .main_general{
            margin-left: 30px;
        }

        .sub_title{
            width:280px;
        }
    }

    @media screen and (max-width: 900px){
        .right{
            width: 300px;
        }
    }

    @media screen and (max-width: 850px){
        .right{
            width: 250px;
        }

        .sub_title{
            width: fit-content;
        }
    }

    @media screen and (max-width: 750px){
        .infos_contact{
            right: 10px;
            left: auto;
            width: 245px;
        }

        .infos{
            display: flex;
            justify-content: flex-end;
            position: relative;
        }

        .main_general{
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 400px){
        .right{
            margin-right: auto;
            margin-left: auto;
        }

        .favicon_message{
            margin-left: 20px;
        }

        .main_general{
            flex-direction: column;
        }
    }
</style>